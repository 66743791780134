import React from 'react';
import { puree, GetInnerProps } from '@ux/pure-enhance';
import Shimmer from '@ux/shimmer';
import { ShortcodeProps } from './';
import { Colorway } from '@ux/theme-utils';

export interface OuterProps {
  against?: Colorway,
  code: ShortcodeProps,
}

// random % width for shimmer betwen 50% and 95%
const randomWidth = () => Math.floor((Math.random() * (95 - 50)) + 50);

const enhance = puree<OuterProps>('PendingShortcode')();

type InnerProps = GetInnerProps<typeof enhance>;

const PurePendingShortcode = ({
  code,
  against,
}: InnerProps) => (
  <div
    style={{
      display: 'inline-block',
      width: `${randomWidth()}%`,
    }}
  >
    <Shimmer
      lines={code && code.elements ? 2 : 1}
      against={against}
    />
  </div>
);

export default enhance(PurePendingShortcode);
