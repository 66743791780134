import { useMemo, useEffect } from 'react';
import { branch, mapProps as mapPropsHoc } from 'recompose';
import pureEnhance from '@ux/pure-enhance';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pipe, withProps, mapProps, withHandlers } from '@team-griffin/rehook';
import { Watch } from 'scrollmonitor-react';
import * as r from 'ramda';
import { sendImpression } from 'application/signals/ribbons';
import { getRibbonAnalytics } from 'domain/selectors/ribbons';
import { Type } from 'domain/constants/ribbons';

const hooksA = pipe(
  mapProps((props: any) => {
    const { id } = props;
    const selector = useMemo(() => getRibbonAnalytics(id), [ id ]);
    const analytics = useSelector(selector);
    const dispatch = useDispatch();
    const actions = bindActionCreators({ sendImpression }, dispatch);

    return r.mergeDeepLeft(props, {
      analytics,
      actions,
    });
  }),
  withHandlers({
    onImpression: ({
      name,
      type,
      analytics,
      actions: {
        sendImpression,
      },
    }: {
      name: string,
      type: Type,
      analytics: any,
      actions: {
        sendImpression: Function,
      }
    }) => () => sendImpression({
      ...analytics,
      ribbonName: name,
      ribbonType: type,
    }),
  }),
);

const hooksB = pipe(
  withProps(({
    isFullyInViewport,
    stopWatcher,
    onImpression,
    analytics,
  }: {
    isFullyInViewport: boolean,
    stopWatcher: () => void,
    onImpression: (a: any) => void,
    analytics: any,
  }) => {
    useEffect(() => {
      if (isFullyInViewport) {
        stopWatcher();
        onImpression(analytics);
      }
    }, [ isFullyInViewport ]);
  }),
);

export default pureEnhance('RibbonImpression', {
  hocs: [
    mapPropsHoc(hooksA),
    branch(
      r.propSatisfies(r.complement(r.isNil), 'analytics'),
      Watch,
    ),
    mapPropsHoc(hooksB),
  ],
});
