import React from 'react';
import pureEnhance from '@ux/pure-enhance';
import { RecursiveShortcodeProps } from './Shortcode';

export default pureEnhance<RecursiveShortcodeProps, RecursiveShortcodeProps>(
  'Bold',
)(({ elements, RecursiveShortcode, ...rest }) => (
  <span style={{ fontStyle: 'italic' }}>
    <RecursiveShortcode
      elements={elements}
      {...rest}
    />
  </span>
));