import React from 'react';
import { withProps } from '@team-griffin/rehook';
import TextButton, {
  Size as TextButtonSize,
} from '@ux/text-button';
import { connect } from '@ux/fabric';
import { selectors as wlSelectors } from '@ux/whitelabel';
import pureEnhance from '@ux/pure-enhance';
import * as r from 'ramda';
import { RecursiveShortcodeProps } from './Shortcode';

const getUsfConfig = wlSelectors.getAppConfig('usf');

const mapStateToProps = r.pipe(
  getUsfConfig,
  r.pathOr([], [ 'externalRoutes' ]),
  r.objOf('externalRoutes'),
);

export default pureEnhance<RecursiveShortcodeProps, RecursiveShortcodeProps>(
  'Link', [
  //@ts-ignore (apparently incompatible states)
    withProps(connect(mapStateToProps)),
  ])(({
  RecursiveShortcode,
  elements,
  contentType,
  attributes,
  theme,
  against,
  externalRoutes,
  ...rest
}) => (
  <TextButton
    theme={theme}
    to={attributes.goto}
    id={attributes.analyticskey ? `shortcode-link-${attributes.analyticskey}` : null}
    against={against}
    contentType={contentType}
    size={TextButtonSize.INHERIT}
    externalRoutes={externalRoutes}
  >
    <RecursiveShortcode
      elements={elements}
      {...rest}
    />
  </TextButton>
));
