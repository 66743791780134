import React, { Fragment } from 'react';
import {
  compose,
  lifecycle,
} from 'recompose';
import { withRouter } from 'react-router';
import { Element, scroller } from 'react-scroll';

const deepLink = compose(
  withRouter,
  lifecycle({
    componentDidMount: function() {
      //Adding some ts-ignores here so it doesn't throw not recognizing
      //this.props

      //@ts-ignore
      if (this.props.location.hash === `#${this.props.name}`) {
        const scrollWhenReady = () => {
          // we can't scroll to eagerly or we'll end up in the wrong place
          //@ts-ignore
          if (this.props.loading || !this.props.animationComplete) {
            return setTimeout(scrollWhenReady, 100);
          }
          //@ts-ignore
          scroller.scrollTo(this.props.name, {
            duration: 500,
            delay: 100,
            smooth: true,
          });
        };
        setTimeout(scrollWhenReady, 100);
      }
    },
  }),
  (WrappedComponent: any) => {
    return (props: any) => (
      <Fragment>
        <Element name={props.name}/>
        <WrappedComponent {...props}/>
      </Fragment>
    );
  },
);

export default deepLink;
