import { gu2px } from '@ux/theme-utils';
import { getTitleColor } from 'presentation/utils/colorway';
import { useStyles } from '@team-griffin/stylr';
import { InnerProps } from './AdvocateTrustpilot';
import { CSSProperties } from 'react';
export interface Styles {
  inner: CSSProperties,
  logo: CSSProperties,
  stars: CSSProperties,
  title: CSSProperties,
}
export default ({
  theme,
  colorway,
  isMinSm,
  isMinLg,
}: InnerProps) => {
  const {
    spacing,
  } = theme;

  return useStyles({
    default: {
      inner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      logo: {
        height: gu2px(4, spacing),
        marginBottom: gu2px(1, spacing),
        textAlign: 'center',
      },
      stars: {
        height: gu2px(3, spacing),
        marginBottom: gu2px(2, spacing),
        textAlign: 'center',
      },
      title: {
        color: getTitleColor(theme, colorway),
        textAlign: 'center',
      },
    },

    isMinLg: {
      logo: {
        height: gu2px(4, spacing),
      },
      stars: {
        height: gu2px(4, spacing),
      },
      reviews: {
        padding: 0,
      },
    },
  }, [
    { isMinSm },
    { isMinLg },
  ]);
};
