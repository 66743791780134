//@ts-ignore
import cssSides, { Side } from '@team-griffin/css-sides';
import { gu2px } from '@ux/theme-utils';
import { content } from '@ux/type-size';
import { getTitleColor, getBodyColor } from 'presentation/utils/colorway';
import { useStyles } from '@team-griffin/stylr';
import { InnerProps } from './Review';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  stars: CSSProperties,
  textContainer: CSSProperties,
  excerpt: CSSProperties,
  byline: CSSProperties,
}
export default ({
  theme,
  colorway,
}: InnerProps) => {
  const {
    typography,
    spacing,
  } = theme;

  return useStyles({
    default: {
      root: {
        textAlign: 'center',
      },
      stars: {
        height: gu2px(3, spacing),
        display: 'flex',
        justifyContent: 'center',
        marginBottom: gu2px(2, spacing),
      },
      textContainer: {
        ...content(typography),
        ...cssSides('margin', Side.A, 0),
        ...cssSides('padding', Side.A, 0),
      },
      excerpt: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: getTitleColor(theme, colorway),
      },
      byline: {
        color: getBodyColor(theme, colorway),
      },
    },
  }, [
    { colorway },
  ]);
};
