import React, { lazy, Suspense } from 'react';
import { withProps } from '@team-griffin/rehook';
import TextButton, { Size as TextButtonSize } from '@ux/text-button';
import { withBreakpointsHook } from '@ux/responsive';
import { gu2px } from '@ux/theme-utils';
import pureEnhance from '@ux/pure-enhance';
import { RecursiveShortcodeProps } from './Shortcode';

/*
  React-Tooltip is a heavy dependency therefore we asynchronously load it in
  and gracefully decorate the functionality in once it has loaded
*/
const ReactTooltip = lazy(() => import(
  /* webpackChunkName: "react-tooltip-lite" */
  'react-tooltip-lite'
));

export default pureEnhance<RecursiveShortcodeProps, RecursiveShortcodeProps>(
  'TooltipShortcode', [
    withBreakpointsHook,
    withProps(({
      theme,
      isMinLg,
    }: RecursiveShortcodeProps) => {
      return {
        eventToggle: isMinLg ? void 0 : 'onClick',
        padding: `${gu2px(2, theme.spacing)}px`, // requires String; gross
      };
    }),
  ],
)(({
  RecursiveShortcode,
  theme,
  against,
  padding,
  eventToggle,
  attributes,
  elements,
}) => {
  const CallToAction = (
    <TextButton
      theme={theme}
      size={TextButtonSize.INHERIT}
      against={against}
    >
      {attributes.label}
    </TextButton>
  );

  return (
    <Suspense fallback={CallToAction}>
      {/*@ts-ignore children doesnt exist */}
      <ReactTooltip
        tagName="span"
        eventToggle={eventToggle}
        useDefaultStyles={true}
        padding={padding}
        content={(
          <div
            style={{
              maxWidth: 320,
            }}
          >
            <RecursiveShortcode
              elements={elements}
            />
          </div>
        )}
      >
        {CallToAction}
      </ReactTooltip>
    </Suspense>
  );
});
