import { Flush } from 'domain/constants/ribbons';
import { gu2px } from '@ux/theme-utils';
import * as r from 'ramda';
import { Layout } from 'domain/constants/layout';

export default ({
  spacing,
  isMinSm,
  isMinLg,
  layout,
  flush,
}: {
  spacing: number,
  isMinSm: boolean,
  isMinLg: boolean,
  layout?: Layout,
  flush?: Flush|boolean,
}) => {

  if (r.equals(flush, true)) {
    // Component will handle all padding, used on full bleed image ribbons
    return {};
  }

  const {
    //@ts-ignore
    shrinkLeading,
    //@ts-ignore
    shrinkTrailing,
  } = r.defaultTo({}, layout);

  /*
    shrinkSide:
      shrink[Leading/Trailing] prop is true then return a reduced padding
      depending on screen size
    isFlush:
      leading or trailing edge has been designated as flush, no padding
  */
  const getPaddingSize = (shrinkSide: string, isFlush: boolean) => {

    if (isFlush) {
      return 0;
    }

    return r.cond([
      [ r.propEq(shrinkSide, true), () => r.ifElse(
        r.equals(true),
        r.always(1),
        r.always(0.5),
      )(isMinSm) ],
      [ r.propEq('isMinLg', true), r.always(6) ],
      [ r.propEq('isMinSm', true), r.always(5) ],
      [ r.T, r.always(4) ],
    ])({
      isMinLg,
      isMinSm,
      shrinkLeading,
      shrinkTrailing,
    });
  };

  const leading = getPaddingSize('shrinkLeading', flush === Flush.LEADING);
  const trailing = getPaddingSize('shrinkTrailing', flush === Flush.TRAILING);

  return {
    paddingTop: gu2px(leading, spacing),
    paddingBottom: gu2px(trailing, spacing),
  };
};
