import React, { Fragment } from 'react';
import { isFalse } from '@team-griffin/capra';
import * as r from 'ramda';
import pureEnhance from '@ux/pure-enhance';

const escapeShortCodes = r.pipe(
  r.replace('[!', '['),
  r.replace('!/]', '/]'),
  r.replace('[/!', '[/'),
);

const nl2br = (string: string) => string.split('\n')
  .map(r.identity)
  //@ts-ignore typescript cant figure typing out
  // eslint-disable-next-line react/no-array-index-key
  .reduce((prev, cur, i) => [ prev, (<br key={i}/>), cur ]);

const formatText = (text: string, newline: boolean|Element) => r.pipe(
  escapeShortCodes,
  // unless newline is false, run through nl2br
  r.unless(
    r.always(isFalse(newline)),
    nl2br,
  ),
)(text);

interface Props {
  text: string,
  nl2br: Element,
}
export default pureEnhance<Props, Props>(
  'Text',
)(({ text, nl2br }) => (
  <Fragment>
    {formatText(text, nl2br)}
  </Fragment>
));
