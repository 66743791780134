import React, { Fragment, CSSProperties, ExoticComponent } from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import { Colorway } from 'domain/constants/colorway';
import { percentage } from '@ux/style-utils';
import * as r from 'ramda';
import { useStyles } from '@team-griffin/stylr';
import pureEnhance from '@ux/pure-enhance';

const logoOnDark = 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/9f2eeecc-b670-4bc1-a366-878f0de5122c/dark-288x72.png'; // eslint-disable-line max-len
const logoOnLight = 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/68137afe-5794-4f19-b4aa-f8d5fd8f0215/light-288x72.png'; // eslint-disable-line max-len

interface OuterProps {
  url: string,
  colorway: Colorway,
}

interface InnerProps extends OuterProps {
  styles: {
    root: CSSProperties,
  },
  logo: string,
  Component: ExoticComponent | 'a',
}
const getLogoForColorway = r.cond([
  [ r.equals(Colorway.DARK), r.always(logoOnDark) ],
  [ r.equals(Colorway.HIGHLIGHT), r.always(logoOnDark) ],
  [ r.equals(Colorway.PRIMARY), r.always(logoOnDark) ],
  [ r.T, r.always(logoOnLight) ],
]);

const stylesheet = () => useStyles({
  default: {
    root: {
      display: 'block',
      maxWidth: percentage(100),
      maxHeight: percentage(100),
    },
  },
});

export const PureLogo = ({
  styles,
  logo,
  url,
  Component,
}: InnerProps) => (
  <Component
    href={url}
    target="_blank"
  >
    {/*
    //@ts-ignore */}
    <img
      loading="lazy"
      src={logo}
      alt="Trustpilot"
      style={styles.root}
    />
  </Component>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Logo',
  [
    withProps((ownerProps: OuterProps) => ({
      logo: getLogoForColorway(ownerProps.colorway),
      Component: r.ifElse(
        r.isEmpty,
        r.always(Fragment),
        r.always('a'),
      )(ownerProps.url),
    })),
    withProps(stylesheet),
  ],
);

export default enhance(PureLogo);
