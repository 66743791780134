import React from 'react';
import * as r from 'ramda';
import pureEnhance from '@ux/pure-enhance';
import { RecursiveShortcodeProps } from './Shortcode';

export default pureEnhance<RecursiveShortcodeProps, RecursiveShortcodeProps>(
  'Alt',
)(({
  RecursiveShortcode,
  elements,
  theme,
  against,
  ...rest
}) => (
  <span style={{
    color: r.cond([
      [ r.equals('LIGHT'), r.always(theme.palette.primary) ],
      [ r.equals('NEUTRAL'), r.always(theme.palette.primary) ],
      [ r.equals('DARK'), r.always(theme.palette.tertiary) ],
      [ r.T, r.always('inherit') ],
    ])(against),
  }}
  >
    <RecursiveShortcode
      elements={elements}
      theme={theme}
      against={against}
      {...rest}
    />
  </span>
));
