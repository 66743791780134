import * as r from 'ramda';

const getFuzzyLang = r.pipe(
  r.defaultTo('en-gb'),
  r.split('-'),
  r.head,
  r.when(
    r.equals('en'),
    r.always('www'),
  )
);

const getLocalizedTrustPilotUrl = (lang, id) => r.ifElse(
  r.isNil,
  r.always(''),
  r.always(`https://${getFuzzyLang(lang)}.trustpilot.com/review/${id}`)
)(id);

export default getLocalizedTrustPilotUrl;