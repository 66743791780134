import React from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import Reviews from './Reviews';
import Ribbon from '../Ribbon';
import Logo from './Logo';
import TrustpilotStars from 'components/common/TrustpilotStars';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Container } from '@ux/grid';
import PaddingBox from '@ux/padding-box';
import { Heading06 } from '@ux/typography';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import errorBoundary from 'presentation/hocs/errorBoundary';
import * as r from 'ramda';
import getLocalizedTrustPilotUrl from 'presentation/utils/getTrustpilotUrl';
import stylesheet, { Styles } from './AdvocateTrustpilot.stylesheet';
import pureEnhance from '@ux/pure-enhance';
import { RibbonProps } from 'domain/constants/ribbons';
import { ReviewProps } from './Review';

export interface OuterProps extends RibbonProps {
  numberOfReviews?: {
    total: number,
    usedForTrustScoreCalculation: number,
    oneStar: number,
    twoStars: number,
    threeStars: number,
    fourStars: number,
    fiveStars: number,
  },
  stars: number,
  trustScore: number,
  reviews: ReviewProps[],
  reviewCount: number,
  url: string,
  currentLanguage: string,
  trustpilotPageId: string,
}

export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
}

export const PureAdvocateTrustpilot = ({
  id,
  styles,
  theme,
  colorway,
  loading,
  layout,
  name,
  stars,
  trustScore,
  reviews,
  reviewCount,
  url,
}: InnerProps) => (
  <Ribbon
    theme={theme}
    colorway={colorway}
    loading={loading}
    layout={layout}
    name={name}
    id={id}
    type="AdvocateTrustpilot"
  >
    <Container>
      <PaddingBox
        theme={theme}
        style={styles.inner}
      >
        <div style={styles.logo}>
          <Logo
            colorway={colorway}
            url={url}
          />
        </div>
        <div style={styles.stars}>
          <TrustpilotStars
            theme={theme}
            rating={stars}
          />
        </div>
        <Heading06
          theme={theme}
          style={styles.title}
          component="p"
        >
          <FormattedMessage
            id="usf.ribbons.advocate.trustpilot.title"
            values={{
              stars,
              trustScore: (
                <FormattedNumber value={trustScore}/>
              ),
              reviewCount: (
                <FormattedNumber value={reviewCount}/>
              ),
            }}
          />
        </Heading06>
      </PaddingBox>

      <Reviews
        theme={theme}
        colorway={colorway}
        reviews={reviews}
      />
    </Container>
  </Ribbon>
);


export const enhance = pureEnhance<InnerProps, OuterProps>(
  'AdvocateTrustpilot',
  {
    hocs: [
      errorBoundary,
    ],
    hooks: [
      withBreakpointsHook,
      withProps((ownerProps: OuterProps) => ({
        reviewCount: r.pathOr(
          null,
          [ 'numberOfReviews', 'usedForTrustScoreCalculation' ],
          ownerProps
        ),
        url: getLocalizedTrustPilotUrl(
          ownerProps.currentLanguage,
          ownerProps.trustpilotPageId
        ),
      })),
      withProps(stylesheet),
    ],
  },
);

export default enhance(PureAdvocateTrustpilot);
