import pureEnhance from '@ux/pure-enhance';
import withRibbonImpression from 'presentation/hocs/withRibbonImpression';

import Ribbon from './Ribbon';

const enhance = pureEnhance('ConnectedRibbon', {
  hocs: [ withRibbonImpression ],
});

export default enhance(Ribbon);
