import React, { useRef, useLayoutEffect, CSSProperties, ReactNode, Ref } from 'react';
import { withProps } from '@team-griffin/rehook';
import pureEnhance from '@ux/pure-enhance';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import findResponsiveItem from 'presentation/utils/findResponsiveItem';
import getBreakpoints from 'presentation/utils/getBreakpoints';
import ribbonPadding from 'presentation/utils/ribbonPadding';
import { duoRibbonWidth } from 'presentation/utils/ribbonWidth';
import { percentage, modifier } from '@ux/style-utils';
import deepLink from 'presentation/hocs/deepLink';
import { getBackgroundColor } from 'presentation/utils/colorway';
import { useStyles } from '@team-griffin/stylr';
import { Colorway } from 'domain/constants/colorway';
import { Fit, Flush, RibbonProps } from 'domain/constants/ribbons';
import * as r from 'ramda';

export {
  Flush,
};

interface OuterProps extends RibbonProps {
  children?: ReactNode,
}
interface WithHandlerProps extends OuterProps {
  setAnimationComplete: Function,
  setRibbonHeight: Function,
  setHeight: Function,
  onAnimationEnd: (props: {
    newHeight: number;
  }) => void,
}

interface InnerProps extends WithHandlerProps, BreakpointProps {
  rendered: boolean,
  targetRef: Ref<any>,
  duration: number,
  styles: {
    root: CSSProperties,
    inner: CSSProperties,
    ribbon: CSSProperties,
  }
}

const mapFit = (fit: Fit) => r.cond([
  [ r.equals(Fit.FULLSCREEN), r.always({
    minHeight: '100vh',
  }) ],
  [ r.equals(Fit.FILL), r.always({
    flex: 1,
    flexBasis: 'auto',
  }) ],
  [ r.equals(Fit.AUTO), r.always({
    height: 'auto',
  }) ],
  [ isFinite, r.always({
    minHeight: fit,
  }) ],
  [ r.T, r.always({
    height: 'auto',
  }) ],
])(fit);

const stylesheet = ({
  theme,
  colorway,
  loading,
  layout,
  flush,
  ...props
}: InnerProps) => {
  const {
    palette,
    spacing,
  } = theme;
  const {
  //@ts-ignore
    fit,
  } = r.defaultTo({}, layout);

  const {
    isMinSm,
    isMinMd,
    isMinLg,
    duoLayout,
  } = props;

  const activeFit = findResponsiveItem(
    getBreakpoints(props),
    fit,
  );

  const padding = ribbonPadding({
    spacing,
    isMinSm,
    isMinLg,
    layout,
    flush,
  });

  const duoLayoutFlex = duoRibbonWidth({
    isMinMd,
    duoLayout,
  });

  return useStyles({
    default: {
      root: {
        backgroundColor: getBackgroundColor(theme, colorway),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: percentage(100),
        ...mapFit(activeFit),
        ...padding,
        ...duoLayoutFlex,
      },
      inner: {
        flex: 1,
        flexBasis: 'auto',
        display: 'flex',
        height: 'auto',
      },
      ribbon: {
        display: 'flex',
        flexDirection: 'column',
        width: percentage(100),
      },
    },

    [modifier('colorway', Colorway.PRIMARY_GRADIENT)]: {
      root: {
        backgroundImage: `linear-gradient(to right, ${palette.primary}, ${palette.primaryGradient})`,
      },
    },
  }, [
    { loading },
    { colorway },
    { isMinSm },
    { isMinMd },
    { isMinLg },
  ]);
};

export const PureRibbon = ({
  styles,
  targetRef,
  children,
}: InnerProps) => (
  <section style={styles.root}>
    <div style={styles.inner}>
      <div
        style={styles.ribbon}
        ref={targetRef}
      >
        {children}
      </div>
    </div>
  </section>
);

export const enhance = pureEnhance('Ribbon', {
  hocs: [
    deepLink,
  ],
  hooks: [
    withProps(() => {
      const targetRef = useRef({});

      if (process.env.NODE_ENV !== 'production') {
        useLayoutEffect(() => {
        // when backstop runs on ribbons, this lets us filter out a lot of false
        // positives and a lot of race condition fails by only initialising
        // the snapshot once loading has finished + a delay to wait for fonts etc to load
        // eslint-disable-next-line
          setTimeout(() => {
            // eslint-disable-next-line
            console.log('BACKSTOP_RIBBON_READY');
          }, 3000);
        }, [ targetRef.current ]);
      }

      return {
        targetRef,
      };
    }),
    withBreakpointsHook,
    withProps(stylesheet),
  ],
});

export default enhance(PureRibbon);
