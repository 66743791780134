import React from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import TrustpilotStars from 'components/common/TrustpilotStars';
import PaddingBox from '@ux/padding-box';
import { Heading04, BodyCopy01 } from '@ux/typography';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import stylesheet, { Styles } from './Review.stylesheet';
import Shortcode, { ShortcodeProps } from 'components/Shortcode';
import { Theme } from '@ux/theme-utils';
import { Colorway } from 'domain/constants/colorway';
import pureEnhance from '@ux/pure-enhance';

export interface ReviewProps {
  stars: number,
  byline: string,
  excerpt: ShortcodeProps,
}
interface OuterProps extends ReviewProps {
  theme: Theme,
  colorway: Colorway,
}

export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
}
export const PureReview = ({
  styles,
  theme,
  colorway,
  stars,
  excerpt,
  byline,
}: InnerProps) => (
  <PaddingBox
    theme={theme}
    style={styles.root}
  >
    <div style={styles.stars}>
      <TrustpilotStars
        theme={theme}
        rating={stars}
      />
    </div>
    <blockquote style={styles.textContainer}>
      <Heading04
        theme={theme}
        component="cite"
        style={styles.excerpt}
      >
        <Shortcode
          theme={theme}
          against={colorway}
          code={excerpt}
        />
      </Heading04>
      <BodyCopy01
        theme={theme}
        style={styles.byline}
      >
        {byline}
      </BodyCopy01>
    </blockquote>
  </PaddingBox>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'AdvocateReview',
  [
    withBreakpointsHook,
    withProps(stylesheet),
  ],
);

export default enhance(PureReview);
