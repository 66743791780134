import { percentage } from '@ux/style-utils';

export const duoRibbonWidth = ({
  isMinMd,
  duoLayout,
}: {
  isMinMd: boolean,
  duoLayout?: boolean,
}) => {
  if (!isMinMd || duoLayout == null) {
    return {};
  }

  const flexSize = (isMinMd && duoLayout) ? 50 : 100;
  return {
    flex: percentage(flexSize),
  };
};

export default null;