import React, { CSSProperties } from 'react';
import {
  compose,
  lifecycle,
  withState,
  withProps,
} from 'recompose';
import Message from '@ux/message';
import { Container } from '@ux/grid';
import * as r from 'ramda';
import cssSides, { Side } from '@team-griffin/css-sides';
import {
  getBackgroundColor,
  getTitleColor,
} from 'presentation/utils/colorway';
import { gu2px, Theme } from '@ux/theme-utils';
import ribbonPadding from 'presentation/utils/ribbonPadding';
import { useStyles } from '@team-griffin/stylr';
import { Colorway } from 'domain/constants/colorway';
import { Layout } from 'domain/constants/layout';
import { Flush } from 'domain/constants/ribbons';
import { BreakpointProps } from '@ux/responsive';
interface OuterProps extends BreakpointProps {
  theme: Theme,
  colorway: Colorway,
  layout: Layout,
  flush?: Flush|boolean,
}

interface InnerProps extends OuterProps {
  styles: {
    root: CSSProperties,
  }
  hasErrored: boolean,
}
const stylesheet = ({
  theme,
  colorway,
  isMinSm,
  isMinMd,
  isMinLg,
  layout,
  flush,
}: InnerProps) => {
  const {
    spacing,
  } = theme;

  return useStyles({
    default: {
      root: {
        backgroundColor: getBackgroundColor(theme, colorway),
        color: getTitleColor(theme, colorway),
        ...ribbonPadding({
          spacing,
          isMinSm,
          isMinLg,
          layout,
          flush,
        }),
        ...cssSides('Side', Side.X, gu2px(3, spacing)),
        textAlign: 'center',
      },
    },
    isMinSm: {
      root: {
        ...cssSides('Side', Side.X, gu2px(4, spacing)),
      },
    },
  }, [
    { colorway },
    { isMinSm },
    { isMinMd },
  ]);
};

export default (WrappedComponent: any) => {
  const ErrorBoundary = r.ifElse(
    r.propEq('hasErrored', true),
    ({ styles }) => (
      <div style={styles.root}>
        <Container>
          <Message id="usf.ribbons.error.text"/>
        </Container>
      </div>
    ),
    (props: InnerProps) => (
      <WrappedComponent {...props}/>
    ),
  );

  return compose(
    withState('hasErrored', 'setHasErrored', false),
    lifecycle({
      componentDidCatch: function() {
        //@ts-ignore
        this.props.setHasErrored(true);
      },
    }),
    withProps(stylesheet),
  )(ErrorBoundary);
};
