import React from 'react';
import pureEnhance from '@ux/pure-enhance';
import { useStyles } from '@team-griffin/stylr';
import { percentage } from '@ux/style-utils';
import { withProps } from '@team-griffin/rehook';

const stylesheet = () => useStyles({
  default: {
    root: {
      maxWidth: percentage(100),
      maxHeight: percentage(100),
      display: 'block',
    },
  },
});


// I'm not worried about the repetition of the prefix here as it'll be normalised away by gzip compression
// as a dictionary item.
const starMap = {
  '1': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/4627f20d-7040-4e7f-9abe-36501b4dd2e8/1-256x48.png', // eslint-disable-line max-len
  '1.5': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/8f0e6ed7-723f-41e5-a402-2fd90025d763/1_5-256x48.png', // eslint-disable-line max-len
  '2': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/a68be276-2c5e-4257-92ed-bc37a6a48950/2-256x48.png', // eslint-disable-line max-len
  '2.5': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/a4e13b3a-499a-46e3-91f2-cc8c4b95e218/2_5-256x48.png', // eslint-disable-line max-len
  '3': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/2f119448-0f7a-4bb5-878a-2a589e768761/3-256x48.png', // eslint-disable-line max-len
  '3.5': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/0b1ee116-1add-4097-a3ad-dbbdcb4b9e41/3_5-256x48.png', // eslint-disable-line max-len
  '4': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/22fcec44-6e44-49db-af28-940f92b03cb0/4-256x48.png', // eslint-disable-line max-len
  '4.5': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/bbb6dfba-fd77-4f0c-b922-ab3cdc8a39af/4_5-256x48.png', // eslint-disable-line max-len
  '5': 'https://img1.wsimg.com/cdn/Image/All/AllChannelsFoS/1/en-US/91a13237-f0a8-49a3-bbf1-d6edf5fc1b69/5-256x48.png', // eslint-disable-line max-len
};


export const PureStars = ({
  styles,
  rating,
}) => (
  <img
    loading="lazy"
    src={starMap[rating]}
    alt={`Trustpilot ${rating}/5`}
    style={styles.root}
  />
);

export const enhance = pureEnhance(
  'TrustpilotStars',
  [ withProps(stylesheet) ]
);

export default enhance(PureStars);
