import { connect } from 'react-redux';
import AdvocateTrustpilot, { OuterProps as PureProps } from './AdvocateTrustpilot';
import { getCurrentLanguage, getTrustpilotPageId } from 'domain/selectors/ribbons';
import { createStructuredSelector } from 'reselect';
import pureEnhance from '@ux/pure-enhance';

interface ConnectedProps {
  currentLanguage: string,
  trustpilotPageId: string,
}
interface InnerProps extends PureProps {}
interface OuterProps extends Omit<InnerProps, keyof ConnectedProps> {}


const mapStateToProps = createStructuredSelector({
  trustpilotPageId: getTrustpilotPageId,
  currentLanguage: getCurrentLanguage,
});

const enhance = pureEnhance<InnerProps, OuterProps>(
  'ConnectedAdvocateTrustPilot',
  {
    hocs: [
      connect(mapStateToProps),
    ],
  },
);

export default enhance(AdvocateTrustpilot);
