import { useStyles } from '@team-griffin/stylr';
import { gu2px } from '@ux/theme-utils';
import { InnerProps } from './Reviews';
import { CSSProperties } from 'react';

export interface Styles {
  review: CSSProperties,
}
export default ({
  theme: {
    spacing,
  },
  isMinSm,
}: InnerProps) => useStyles({
  default: {
    review: {
      marginTop: gu2px(1, spacing),
    },
  },

  isMinSm: {
    review: {
      marginTop: gu2px(2, spacing),
    },
  },
}, [
  { isMinSm },
]);
