import React from 'react';
import { RecursiveShortcodeProps } from './Shortcode';
import pureEnhance from '@ux/pure-enhance';

export default pureEnhance<RecursiveShortcodeProps, RecursiveShortcodeProps>(
  'Bold',
)(({ elements, RecursiveShortcode, ...rest }) => (
  <span style={{ fontWeight: 'bold' }}>
    <RecursiveShortcode
      elements={elements}
      {...rest}
    />
  </span>
));
