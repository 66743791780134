import React from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import { Row, Column, Justify } from '@ux/grid';
import stylesheet, { Styles } from './Reviews.stylesheet';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import Review, { ReviewProps } from './Review';
import { Theme } from '@ux/theme-utils';
import { Colorway } from 'domain/constants/colorway';
import pureEnhance from '@ux/pure-enhance';

interface OuterProps {
  theme: Theme,
  colorway: Colorway,
  reviews: ReviewProps[],
}

export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
}

declare const i: number;
declare const review: ReviewProps;

export const PureAdvocateTrustpilot = ({
  theme,
  colorway,
  reviews,
  styles,
}: InnerProps) => (
  <Row
    justify={Justify.CENTER}
  >
    <For
      each="review"
      of={reviews}
      index="i"
    >
      <Column
        key={i}
        xs={12}
        sm={6}
        md={4}
        lg={3}
      >
        <div style={styles.review}>
          <Review
            theme={theme}
            colorway={colorway}
            {...review}
          />
        </div>
      </Column>
    </For>
  </Row>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'AdvocateTrustpilot',
  [
    withBreakpointsHook,
    withProps(stylesheet),
  ],
);

export default enhance(PureAdvocateTrustpilot);
