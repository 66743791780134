import React, { Fragment, Suspense, lazy, ReactNode } from 'react';
import pureEnhance from '@ux/pure-enhance';
import PendingShortcode from './PendingShortcode';
import { getAgainst } from 'presentation/utils/colorway';
import { nuller } from '@team-griffin/capra';
import { Theme } from '@ux/theme-utils';
import { Layout } from 'domain/constants/layout';
import { Styles } from './stylesheets';
import { BreakpointProps } from '@ux/responsive';
import { ShortcodeProps } from '.';
import * as r from 'ramda';
import { Against } from '@ux/button';

/*
  These shortcodes should not be lazy loaded in as they are already in the
  main bundle and it causes a unnecessary shimmer which makes the page feel
  less responsive
*/
import Alt from './Alt';
import Bold from './Bold';
import Italic from './Italic';
import Link from './Link';
import Text from './Text';
import Tooltip from './Tooltip';

const Glyph = lazy(() => import(
  /* webpackChunkName: "shortcode-glyph" */
  './Glyph'
));

const Sup = lazy(() => import(
  /* webpackChunkName: "shortcode-sup" */
  './Sup'
));

const List = lazy(() => import(
  /* webpackChunkName: "shortcode-list" */
  './List'
));

const OrderedList = lazy(() => import(
  /* webpackChunkName: "shortcode-ordered-list" */
  './OrderedList'
));

const ListItem = lazy(() => import(
  /* webpackChunkName: "shortcode-list-item" */
  './ListItem'
));

const TickListItem = lazy(() => import(
  /* webpackChunkName: "shortcode-tick-list-item" */
  './TickListItem'
));

const ProductPrice = lazy(() => import(
  /* webpackChunkName: "shortcode-product-price" */
  './ProductPrice'
));

const PhoneNumber = lazy(() => import(
  /* webpackChunkName: "shortcode-phone-number" */
  './PhoneNumber'
));

declare const i: number;
declare const element: ShortcodeProps;

export interface RecursiveShortcodeProps extends BreakpointProps{
  elements: ShortcodeProps[],
  layout?: Layout,
  RecursiveShortcode: React.LazyExoticComponent<any>,
  theme?: Theme,
  against?: Against,
  styles?: Styles,
  attributes?: {
    use?: string,
    number?: number,
    analyticskey?: string,
    goto?: string,
    label?: ReactNode,
    prices?: string,
    inctax?: string,
  }
  nl2br?: boolean,
  rest: {
    [key: string]: any,
  }
  [key: string]: any,
}
const RecursiveShortcode = ({ elements, ...rest }: RecursiveShortcodeProps) => (
  <Fragment>
    <For
      each="element"
      index="i"
      of={r.defaultTo([], elements)}
    >
      <Shortcode
        key={i}
        code={element}
        {...rest}
      />
    </For>
  </Fragment>
);

export interface ShortcodeOuterProps {
  theme?: Theme,
  against?: Against,
  RecursiveShortcode: React.LazyExoticComponent<any>,
  code: RecursiveShortcodeProps,
}

function genericShortcodeHandler(
  shortcodeName: string,
  Component: React.LazyExoticComponent<any> | React.ComponentType<any>,
) {
  return [
    r.both(
      r.propEq('type', 'element'),
      r.propEq('name', shortcodeName),
    ),
    (props: ShortcodeOuterProps) => (
      <Component {...props}/>
    ),
  ];
}

const Shortcode = ({ code, ...rest }: ShortcodeOuterProps) => {
  const props = r.pipe(
    r.ifElse(
      () => r.is(String, code),
      r.assoc('code', code),
      r.mergeDeepLeft(code),
    ),
    r.over(
      r.lensProp('against'),
      (colorway) => getAgainst(colorway),
    ),
    r.assoc('RecursiveShortcode', RecursiveShortcode),
    r.over(
      r.lensProp('attributes'),
      r.defaultTo({}),
    ),
  )(rest);

  // @ts-ignore
  const Element = r.cond([
    [
      r.isNil,
      nuller,
    ],
    [
      r.propIs(String, 'code'),
      () => (
        //@ts-ignore
        <Text text={code}/>
      ),
    ],
    [
      r.propEq('type', 'text'),
      () => (
        <Text {...props}/>
      ),
    ],
    genericShortcodeHandler('i', Italic),
    genericShortcodeHandler('b', Bold),
    genericShortcodeHandler('alt', Alt),
    genericShortcodeHandler('sup', Sup),
    genericShortcodeHandler('glyph', Glyph),
    genericShortcodeHandler('tel', PhoneNumber),
    genericShortcodeHandler('link', Link),
    genericShortcodeHandler('productprice', ProductPrice),
    genericShortcodeHandler('tooltip', Tooltip),
    [
      r.both(
        r.propEq('type', 'element'),
        r.propEq('name', 'list'),
      ),
      () => r.ifElse(
        r.propEq('style', 'number'),
        () => (
          <OrderedList {...props}/>
        ),
        () => (
          <List {...props}/>
        ),
      )(props.attributes),
    ],
    [
      r.both(
        r.propEq('type', 'element'),
        r.propEq('name', 'li'),
      ),
      () => r.ifElse(
        r.propEq('style', 'glyph'),
        () => (
          <TickListItem
            {...props}
            glyph={r.propOr('tick', 'glyph', props.attributes)}
          />
        ),
        () => (
          <ListItem {...props}/>
        ),
      )(props.attributes),
    ],
    //@ts-ignore
    genericShortcodeHandler('root', RecursiveShortcode),
    [
      r.T,
      nuller,
    ],
  ]);

  return (
    <Element {...props}/>
  );
};

const ShortcodeWrapper = (props: ShortcodeOuterProps) => (
  <Suspense
    fallback={(
      <PendingShortcode {...props}/>
    )}
  >
    <Shortcode {...props}/>
  </Suspense>
);

const enhance = pureEnhance('Shortcode');

export default enhance(ShortcodeWrapper);
